import { Box, Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { default as React, useEffect, useState } from "react";
import "./TestStatusPage.css";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const JsonDataDisplay = ({ data }) => (
    <Box component="pre" sx={{ overflow: 'auto', marginY: 2 }}>
        {JSON.stringify(data, null, 2)}
    </Box>
);

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <TableRow {...otherProps}>
                <TableCell>
                    <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {children}
            </TableRow>
            {isOpen && (
                <TableRow>
                    <TableCell colSpan={7}>{expandComponent}</TableCell>
                </TableRow>
            )}
        </>
    );
};

function TestStatusPage() {
    const [sourceData, setSourceData] = useState([]);
    const [destinationData, setDestinationData] = useState([]);

    const flows = {
        pulley: {
            "flow_type": "pulley",
            "flow": {
                "description": "Demo Pulley Flow.",
                "name": "pulley-flow",
                "mapping": {
                    "operation_type": "pulley",
                    "source": {
                        "name": "ex-source-node",
                        "fields": [
                            {
                                "name": "description"
                            },
                            {
                                "name": "id"
                            }
                        ]
                    },
                    "destination": {
                        "name": "none"
                    },
                    "operations": [
                        {
                            "type": "map",
                            "params": {
                                "function": "rename_keys",
                                "input": {
                                    "from": "description",
                                    "to": "new_field1"
                                }
                            }
                        }
                    ]
                }
            }
        },
        transport: {
            "data": {
                "description": "Demo Transport Flow.",
                "id": "e7dac184-3d09-431d-9fc7-7d562c36fa1e",
                "mapping": {
                    "destination": {
                        "fields": [
                            {
                                "name": "new_field1"
                            },
                            {
                                "name": "id"
                            }
                        ],
                        "name": "ex-destination-node"
                    },
                    "operation_type": "transport",
                    "operations": [
                        {
                            "params": {
                                "function": "rename_keys",
                                "input": {
                                    "from": "friendly_test",
                                    "to": "test_again"
                                }
                            },
                            "type": "map"
                        }
                    ],
                    "source": {
                        "fields": [],
                        "name": "none"
                    }
                },
                "name": "transport-flow"
            }
        },
        bridge: {
            "data": {
                "description": "Demo Bridge Flow.",
                "id": "c7f6651f-c536-4d6c-98ba-3e8868082c3b",
                "mapping": {
                    "destination": {
                        "fields": [
                            {
                                "name": "new_field1"
                            },
                            {
                                "name": "id"
                            }
                        ],
                        "name": "ex-destination-node"
                    },
                    "operation_type": "bridge",
                    "operations": [
                        {
                            "params": {
                                "function": "rename_keys",
                                "input": {
                                    "from": "description",
                                    "to": "new_field1"
                                }
                            },
                            "type": "map"
                        }
                    ],
                    "source": {
                        "fields": [
                            {
                                "name": "description"
                            },
                            {
                                "name": "id"
                            }
                        ],
                        "name": "ex-source-node"
                    }
                },
                "name": "bridge-flow"
            }
        },
    };

    const nodes = {
        pulley: {
            source: {
                "data": {
                    "description": "Our Example Source Node",
                    "id": "a9f2b5c7-b698-4dba-8b64-56f8abdbfffb",
                    "metadata": {
                        "auth": {
                            "type": "none"
                        },
                        "headers": {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    },
                    "name": "ex-source-node",
                    "url": "https://source.test.laminar.run/"
                }
            }
        },
        transport: {
            destination: {
                "data": {
                    "description": "Our Example Destination Node",
                    "id": "2fd9c6d3-5412-4adc-ad4c-331f602b935c",
                    "metadata": {
                        "auth": {
                            "type": "none"
                        },
                        "headers": {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    },
                    "name": "ex-destination-node",
                    "url": "https://destination.test.laminar.run/"
                }
            },
        },
        bridge: {
            source: {
                "data": {
                    "description": "Our Example Source Node",
                    "id": "a9f2b5c7-b698-4dba-8b64-56f8abdbfffb",
                    "metadata": {
                        "auth": {
                            "type": "none"
                        },
                        "headers": {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    },
                    "name": "ex-source-node",
                    "url": "https://source.test.laminar.run/"
                }
            },
            destination: {
                "data": {
                    "description": "Our Example Destination Node",
                    "id": "2fd9c6d3-5412-4adc-ad4c-331f602b935c",
                    "metadata": {
                        "auth": {
                            "type": "none"
                        },
                        "headers": {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        }
                    },
                    "name": "ex-destination-node",
                    "url": "https://destination.test.laminar.run/"
                }
            },
        }
    }

    useEffect(() => {
        // Fetch data initially
        fetchData();

        // Schedule periodic fetches every 1 second
        const intervalId = setInterval(fetchData, 1000);

        // Clean up interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    function fetchData() {
        console.log("Fetching data...");
        fetch("https://source.test.laminar.run/test-status")
            .then(response => response.json())
            .then(data => setSourceData(data.data.map(status => ({ ...status, body: status.body }))))
            .catch(error => console.error(error));

        fetch("https://destination.test.laminar.run/test-status")
            .then(response => response.json())
            .then(data => setDestinationData(data.data.map(status => ({ ...status, body: status.body }))))
            .catch(error => console.error(error));
    }

    function clear(server) {
        return () => {
            const url = `https://${server}.test.laminar.run/test-status`;
            fetch(url, { method: "DELETE" });
        };
    }

    return (
        <div className="test-status-page">
            <div className="api-intro">
                <h1>Try out the Laminar API!</h1>
                <p>Source and destination servers will log the requests they receive.</p>
                <p>You can read our documentation at <a href="https://docs.laminar.run" target="_blank" rel="noopener noreferrer">https://docs.laminar.run</a></p>
            </div>
            <div className="test-status-page-tables">
                <TableContainer component={Paper}>
                    <Button variant="contained" color="primary" onClick={clear("source")}>Clear</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Route</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Body</TableCell>
                                <TableCell>Headers</TableCell>
                                <TableCell>Query</TableCell>
                                <TableCell>Timestamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sourceData.map((status, index) => (
                                <ExpandableTableRow
                                    key={index}
                                    expandComponent={
                                        <>
                                            <Typography variant="h6" gutterBottom component="div">Body</Typography>
                                            <JsonDataDisplay data={status.body} />
                                            <Typography variant="h6" gutterBottom component="div">Headers</Typography>
                                            <JsonDataDisplay data={status.headers} />
                                            <Typography variant="h6" gutterBottom component="div">Query</Typography>
                                            <JsonDataDisplay data={status.query} />
                                        </>
                                    }
                                >
                                    <TableCell>{status.route}</TableCell>
                                    <TableCell>{status.status}</TableCell>
                                    <TableCell colSpan={3}>Click row to expand details</TableCell>
                                    <TableCell>{status.timestamp}</TableCell>
                                </ExpandableTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper}>
                    <Button variant="contained" color="primary" onClick={clear("destination")}>Clear</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Route</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Body</TableCell>
                                <TableCell>Headers</TableCell>
                                <TableCell>Query</TableCell>
                                <TableCell>Timestamp</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {destinationData.map((status, index) => (
                                <ExpandableTableRow
                                    key={index}
                                    expandComponent={
                                        <>
                                            <Typography variant="h6" gutterBottom component="div">Body</Typography>
                                            <JsonDataDisplay data={status.body} />
                                            <Typography variant="h6" gutterBottom component="div">Headers</Typography>
                                            <JsonDataDisplay data={status.headers} />
                                            <Typography variant="h6" gutterBottom component="div">Query</Typography>
                                            <JsonDataDisplay data={status.query} />
                                        </>
                                    }
                                >
                                    <TableCell>{status.route}</TableCell>
                                    <TableCell>{status.status}</TableCell>
                                    <TableCell colSpan={3}>Click row to expand details</TableCell>
                                    <TableCell>{status.timestamp}</TableCell>
                                </ExpandableTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}

export default TestStatusPage;
